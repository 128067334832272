import { Container, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import certikSVG from "assets/svgs/certik.svg";
import useCommonStyles from "styles/common";
import { IDO_URL } from "config/constants";

const useStyles = makeStyles((theme: any) => ({
  root: {
    background: "#1C2535",
    textAlign: "center",
    paddingBottom: "48px",
  },
  container: {},
  logo: {
    width: "102px",
  },
  listWrapper: {
    paddingTop: "64px",
    display: "flex",
    justifyContent: "space-around",
    color: "white",
    textAlign: "left",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      width: "312px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  menuTitle: {
    fontSize: 14,
  },
  menuList: {
    marginTop: 10,
  },
  menuItem: {
    padding: "2px 0px 2px 0px",
  },
  menuItemLink: {
    textDecoration: "none",
    color: "#cdd4df",
    fontSize: 14,
  },
  copyright: {
    color: "#cdd4df",
    fontSize: 12,
    lineHeight: "20px",
  },
  socialLogo: {
    height: 27,
    marginRight: 20,
  },
  full: {
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
    },
  },
  half: {
    paddingLeft: "5px",
    paddingRight: "5px",

    [theme.breakpoints.down("xs")]: {
      flexBasis: "50%",
      marginTop: "32px",
    },
  },
  auditWrapper: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px",
    },
  },

  certikWrapper: {
    color: "white",
    fontSize: "14px",
    lineHeight: "24px",
  },
}));

// const companyItems = [
//   {
//     link: `${IDO_URL}/apply`,
//     name: "Apply for IDO",
//   },
//   {
//     link: "/blog",
//     name: "Blogs",
//   },
//   {
//     link: "https://ideaology-1.gitbook.io/ideaology-1",
//     name: "RoadMap",
//     target: "_blank",
//   },
// ];

const helpItems = [
  {
    link: "/contact",
    name: "Contact us",
  },
  {
    link: "/terms",
    name: "Terms",
  },
  // {
  //     link: "/privacy",
  //     name: "Privacy",
  // },
  {
    link: "/staking-vesting",
    name: "Staking & Vesting",
  },
  {
    link: "/privacy-policy",
    name: "Privacy Policy",
  },
];

const developerItems = [
  {
    link: "https://ideaology-2.gitbook.io/what-is-ideaido/",
    name: "Documentation",
  },
];

const socialItems = [
  {
    link: "https://ideaologyio.medium.com/",
    name: "medium",
    imgSrc: "/imgs/logo/medium.svg",
  },
  {
    link: "https://t.me/ideaologyio",
    name: "telegram",
    imgSrc: "/imgs/logo/telegram.svg",
  },
  {
    link: "https://twitter.com/ideaologyio",
    name: "twitter",
    imgSrc: "/imgs/logo/twitter.svg",
  },
];

interface IProps {
  className?: string;
}

export const Footer = (props: IProps) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const currentYear = new Date().getFullYear();
  return (
    <div className={clsx(classes.root, props.className)}>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.listWrapper}>
          <div className={classes.full}>
            <img
              alt="logo"
              className={classes.logo}
              src="/imgs/logo/logo-white.webp"
              width="102px"
              height="33px"
            />
            <div className={classes.menuList}>
              <div className={clsx(classes.menuItem, classes.copyright)}>
                Copyright @ {currentYear} Ideaology.io <br /> All Rights
                Reserved
              </div>
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Company</span>
            <div className={classes.menuList}>
              <div className={classes.menuItem}>
                <a className={classes.menuItemLink} href={`${IDO_URL}/apply`}>
                  Apply for IDO
                </a>
              </div>
              <div className={classes.menuItem}>
                <Link className={classes.menuItemLink} to="/blog">
                  Blogs
                </Link>
              </div>
              <div className={classes.menuItem}>
                <a
                  className={classes.menuItemLink}
                  href="https://ideaology-1.gitbook.io/ideaology-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  RoadMap
                </a>
              </div>
              {/* {companyItems.map((element) => (
                <div className={classes.menuItem} key={element.name}>
                  <a
                    className={classes.menuItemLink}
                    href={element.link}
                    target={element.target}
                  >
                    {element.name}
                  </a>
                </div>
              ))} */}
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Help</span>
            <div className={classes.menuList}>
              {helpItems.map((element) => (
                <div className={classes.menuItem} key={element.name}>
                  <Link className={classes.menuItemLink} to={element.link}>
                    {element.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Developers</span>
            <div className={classes.menuList}>
              <div className={classes.menuList}>
                {developerItems.map((element) => (
                  <div className={classes.menuItem} key={element.name}>
                    <a className={classes.menuItemLink} href={element.link}>
                      {element.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Social</span>
            <div className={classes.menuList}>
              {socialItems.map((element) => (
                <a href={element.link} key={element.name}>
                  <img
                    alt={element.name}
                    className={classes.socialLogo}
                    src={element.imgSrc}
                    width="27px"
                    height="27px"
                  />
                </a>
              ))}
            </div>
          </div>
          <div className={clsx(classes.full, classes.auditWrapper)}>
            <a
              className={commonClasses.noDecoration}
              href="https://www.certik.org/projects/ideaology"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className={classes.certikWrapper}>
                <span>Audited by:</span>
                <br />
                <img alt="certik" src={certikSVG} width="149px" height="41px" />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
