const color = {
    transparent: "#0000",
    white: "#fff",
    black: "#000",
    primary: "#0C71BC",
    secondary: "#56606f",
    third: "#fce9ef",
    darkBlue: "#19527D",
};
export default color;
