import { Button, Container, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { IDO_URL } from "config/constants";

import heroPNG from "assets/pngs/hero.webp";
import crossRightPNG from "assets/pngs/landing/cross-right.webp";
import certikSVG from "assets/svgs/certik.svg";

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: "640px",
    background: "#081015",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",

    position: "relative",
  },
  container: {
    display: "flex",
    alignItems: "center",
    overflowX: "visible",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  heroContainer: {
    height: "640px",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "100vw",

    position: "relative",
    left: "-70px",

    overflowY: "hidden",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
      left: "0px",

      display: "flex",
      justifyContent: "center",
      marginTop: "52px",
    },
  },
  heroImg: {
    display: "block",
    width: "850px",
    height: "654px",

    [theme.breakpoints.down("xs")]: {
      width: "382px",
      height: "auto",
    },
  },
  heroText: {
    zIndex: 10,
  },
  contentText: {
    width: "500px",
    fontSize: 48,
    fontWeight: 700,
    color: "#FFFFFF",
    textAlign: "left",

    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: "normal",

      width: "271px",
      marginTop: "80px",
    },
  },
  buttonWrapper: {
    marginTop: 24,
    display: "flex",
  },
  gap: {
    width: "16px",
  },
  poolButton: {
    background: "#FFFFFF",
    color: "#1C2535",

    width: 139,
    height: 40,

    fontSize: 16,
  },
  communityButton: {
    background: "#FD4975",
    color: "#FFFFFF",

    width: 139,
    height: 40,
    fontSize: 16,

    "&:hover": {
      backgroundColor: "#FE87A4",
    },
  },
  linesLeft: {
    backgroundImage: "url(/imgs/landing/lines-1.svg)",

    position: "absolute",
    top: 0,
    left: 0,

    width: "663px",
    height: "640px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  linesRight: {
    backgroundImage: "url(/imgs/landing/lines-2.svg)",

    position: "absolute",
    top: 0,
    right: 0,

    width: "796px",
    height: "640px",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  crossLeft: {
    backgroundImage: "url(/imgs/landing/cross-left.svg)",

    position: "absolute",
    bottom: 0,
    left: 0,

    width: "133px",
    height: "76px",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  crossRight: {
    backgroundImage: `url(${crossRightPNG})`,

    position: "absolute",
    bottom: 0,
    right: 0,

    width: "133px",
    height: "50px",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  auditWrapper: {
    position: "relative",
  },
  certikWrapper: {
    position: "absolute",
    marginTop: "45px",

    color: "white",
    fontSize: "14px",
    lineHeight: "24px",

    display: "flex",
    flexWrap: "wrap",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      width: "294px",
    },
  },
  heroCertikImg: {},
  break: {
    flexBasis: "100%",
    height: 0,

    [theme.breakpoints.down("xs")]: {
      flexBasis: "8px",
    },
  },
}));

interface IProps {
  className?: string;
}

export const Hero = (props: IProps) => {
  const classes = useStyles();

  const onPools = () => {};
  const onJoinCommunity = () => {};

  return (
    <div className={clsx(classes.root, props.className)}>
      <div className={classes.linesLeft}></div>
      <div className={classes.linesRight}></div>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.heroText}>
          <div className={classes.contentText}>
            Support Bright Ideas Through Decentralised Crowdfunding
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              classes={{ root: classes.communityButton }}
              disableElevation
              href={`${IDO_URL}/apply`}
              onClick={onJoinCommunity}
              variant="contained"
              className="d-none"
            >
              Apply for IDO
            </Button>
            <div className={classes.gap} />
            <Button
              classes={{ root: classes.poolButton }}
              disableElevation
              href={`${IDO_URL}/pools`}
              onClick={onPools}
              variant="contained"
              className="d-none"
            >
              Pools
            </Button>
          </div>
          <div className={classes.auditWrapper}>
            <a
              href="https://www.certik.org/projects/ideaology"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className={classes.certikWrapper}>
                <span>Audited by:</span>
                <div className={classes.break} />
                <img
                  alt="certik"
                  className={classes.heroCertikImg}
                  src={certikSVG}
                  width="149px"
                  height="36px"
                />
              </div>
            </a>
          </div>
        </div>
        <div className={classes.heroContainer}>
          <img
            alt="hero"
            className={classes.heroImg}
            src={heroPNG}
            width="850px"
            height="654px"
          />
        </div>
      </Container>
      <div className={classes.crossLeft}></div>
      <div className={classes.crossRight}></div>
    </div>
  );
};
